@import url(https://fonts.googleapis.com/css?family=Krub:300,600);

/* @import url('https://fonts.googleapis.com/css?family=Niramit:200,400,600'); */
/* @import url('https://fonts.googleapis.com/css?family=Slabo:200,400,600'); */

html {
	box-sizing: border-box;
	height: 100%;
	font-family: Krub, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
	font-size: 17px;
	line-height: 1.4;
	letter-spacing: -0.3px;
	font-weight: 300;

	/* colors are set in app style */
}

*, *:after, *:before {
	box-sizing: inherit;
	margin: 0;
	padding: 0;
}

body {
	height: 100%;
	font-size: 1rem;
	transition: font-size 0.3s;
}

#root {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
}

@media (max-width: 900px) {
	body {
		font-size: 0.95rem;
	}
}

@media (max-width: 800px) {
	body {
		font-size: 0.90rem;
	}
}

@media (max-width: 700px) {
	body {
		font-size: 0.85rem;
	}
}

@media (max-width: 600px) {
	body {
		font-size: 0.80rem;
	}
}

@media (max-width: 500px) {
	body {
		font-size: 0.75rem;
	}
}

